/* original */
/* .flip-clock {
    --fcc-flip-duration: 0.5s; transition duration when flip card
    --fcc-digit-block-width: 40px; width of digit card
    --fcc-digit-block-height: 64px; height of digit card, highly recommend in even number
    --fcc-digit-font-size: 50px; font size of digit
    --fcc-label-font-size: 16px; font size of label
    --fcc-digit-color: white; color of digit
    --fcc-background: white; background of digit card
    --fcc-label-color: DimGray; color of label
    --fcc-divider-color: #ffffff66; color of divider
} */

.flip-clock {
    --fcc-flip-duration: 0.5s; /* transition duration when flip card */
    --fcc-digit-block-width: 16px; /* width of digit card */
    --fcc-digit-block-height: 42px; /* height of digit card, highly recommend in even number */
    --fcc-digit-font-size: 20px; /* font size of digit */
    --fcc-label-font-size: 15px; /* font size of label */
    --fcc-digit-color: white; /* color of digit */
    --fcc-background: #6465e9; /* background of digit card */
    --fcc-label-color: DimGray; /* color of label */
    --fcc-divider-color: #ffffff66; /* color of divider */
}

.flip-clock-mobil {
    --fcc-flip-duration: 0.5s; /* transition duration when flip card */
    --fcc-digit-block-width: 16px; /* width of digit card */
    --fcc-digit-block-height: 42px; /* height of digit card, highly recommend in even number */
    --fcc-digit-font-size: 20px; /* font size of digit */
    --fcc-label-font-size: 15px; /* font size of label */
    --fcc-digit-color: white; /* color of digit */
    --fcc-background: #6465e9; /* background of digit card */
    --fcc-label-color: DimGray; /* color of label */
    --fcc-divider-color: #ffffff66; /* color of divider */
}

/* label color */
/* ._2aOn7 {
    color: DimGray !important;
} */

/* center clock */
._qWTcH {
    display: flex;
    align-items: center;
    justify-content: center;
}
